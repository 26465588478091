export enum CognitoError {
  CodeMismatchException = 'CodeMismatchException',
  CognitoDefaultError = 'CognitoDefaultError',
  ExpiredCodeException = 'ExpiredCodeException',
  InternalErrorException = 'InternalErrorException',
  InvalidPasswordException = 'InvalidPasswordException',
  LimitExceededException = 'LimitExceededException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  TooManyRequestsException = 'TooManyRequestsException',
  UserAlreadyAuthenticatedException = 'UserAlreadyAuthenticatedException',
  UsernameExistsException = 'UsernameExistsException',
  UserNotFoundException = 'UserNotFoundException',
  UserUnAuthenticatedException = 'UserUnAuthenticatedException',
}
