import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import http, { ApiResponse } from '../../../../config/http';
import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { UserExtended } from '../../types';
import { getCurrentCognitoUser } from '../../utils/cognito';

const fetchUserExtended = async () => {
  const { data } = await http.get<ApiResponse<UserExtended>>('/users');
  return data.data;
};

export const useUserData = (
  options?: Omit<UseQueryOptions<UserExtended>, 'queryKey'>,
) => {
  const { data: user } = useUser();

  return useQuery({
    enabled: user !== null && user !== undefined,
    queryFn: fetchUserExtended,
    queryKey: QueryKey.UserData(),
    ...options,
  });
};

export const useUser = () => {
  const query = useQuery({
    queryFn: getCurrentCognitoUser,
    queryKey: QueryKey.User(),
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return query;
};

export const invalidateUser = () =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.User(),
    refetchType: 'all',
    type: 'all',
  });

export const invalidateUserData = () =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.UserData(),
    refetchType: 'all',
    type: 'all',
  });
