import { PaddleSupportedCountries } from '../../types/locale';

const translationsCountries: Record<
  PaddleSupportedCountries,
  { name: string }
> = {
  [PaddleSupportedCountries.AD]: { name: 'Andorra' },
  [PaddleSupportedCountries.AE]: { name: 'United Arab Emirates' },
  [PaddleSupportedCountries.AG]: { name: 'Antigua and Barbuda' },
  [PaddleSupportedCountries.AI]: { name: 'Anguilla' },
  [PaddleSupportedCountries.AL]: { name: 'Albania' },
  [PaddleSupportedCountries.AM]: { name: 'Armenia' },
  [PaddleSupportedCountries.AO]: { name: 'Angola' },
  [PaddleSupportedCountries.AR]: { name: 'Argentina' },
  [PaddleSupportedCountries.AS]: { name: 'American Samoa' },
  [PaddleSupportedCountries.AT]: { name: 'Austria' },
  [PaddleSupportedCountries.AU]: { name: 'Australia' },
  [PaddleSupportedCountries.AW]: { name: 'Aruba' },
  [PaddleSupportedCountries.AX]: { name: 'Åland Islands' },
  [PaddleSupportedCountries.AZ]: { name: 'Azerbaijan' },
  [PaddleSupportedCountries.BA]: { name: 'Bosnia and Herzegovina' },
  [PaddleSupportedCountries.BB]: { name: 'Barbados' },
  [PaddleSupportedCountries.BD]: { name: 'Bangladesh' },
  [PaddleSupportedCountries.BE]: { name: 'Belgium' },
  [PaddleSupportedCountries.BF]: { name: 'Burkina Faso' },
  [PaddleSupportedCountries.BG]: { name: 'Bulgaria' },
  [PaddleSupportedCountries.BH]: { name: 'Bahrain' },
  [PaddleSupportedCountries.BI]: { name: 'Burundi' },
  [PaddleSupportedCountries.BJ]: { name: 'Benin' },
  [PaddleSupportedCountries.BL]: { name: 'Saint Barthélemy' },
  [PaddleSupportedCountries.BM]: { name: 'Bermuda' },
  [PaddleSupportedCountries.BN]: { name: 'Brunei Darussalam' },
  [PaddleSupportedCountries.BO]: { name: 'Bolivia' },
  [PaddleSupportedCountries.BQ]: { name: 'Bonaire, Sint Eustatius and Saba' },
  [PaddleSupportedCountries.BR]: { name: 'Brazil' },
  [PaddleSupportedCountries.BS]: { name: 'Bahamas' },
  [PaddleSupportedCountries.BT]: { name: 'Bhutan' },
  [PaddleSupportedCountries.BV]: { name: 'Bouvet Island' },
  [PaddleSupportedCountries.BW]: { name: 'Botswana' },
  //   [PaddleSupportedCountries.BY]: { name: 'Belarus' },
  [PaddleSupportedCountries.BZ]: { name: 'Belize' },
  [PaddleSupportedCountries.CA]: { name: 'Canada' },
  [PaddleSupportedCountries.CC]: { name: 'Cocos (Keeling) Islands' },
  [PaddleSupportedCountries.CG]: { name: 'Congo' },
  [PaddleSupportedCountries.CH]: { name: 'Switzerland' },
  [PaddleSupportedCountries.CI]: { name: "Côte d'Ivoire" },
  [PaddleSupportedCountries.CK]: { name: 'Cook Islands' },
  [PaddleSupportedCountries.CL]: { name: 'Chile' },
  [PaddleSupportedCountries.CM]: { name: 'Cameroon' },
  [PaddleSupportedCountries.CN]: { name: 'China' },
  [PaddleSupportedCountries.CO]: { name: 'Colombia' },
  [PaddleSupportedCountries.CR]: { name: 'Costa Rica' },
  [PaddleSupportedCountries.CV]: { name: 'Cabo Verde' },
  [PaddleSupportedCountries.CW]: { name: 'Curacao' },
  [PaddleSupportedCountries.CX]: { name: 'Christmas Island' },
  [PaddleSupportedCountries.CY]: { name: 'Cyprus' },
  [PaddleSupportedCountries.CZ]: { name: 'Czech Republic' },
  [PaddleSupportedCountries.DE]: { name: 'Germany' },
  [PaddleSupportedCountries.DJ]: { name: 'Djibouti' },
  [PaddleSupportedCountries.DK]: { name: 'Denmark' },
  [PaddleSupportedCountries.DM]: { name: 'Dominica' },
  [PaddleSupportedCountries.DO]: { name: 'Dominican Republic' },
  [PaddleSupportedCountries.DZ]: { name: 'Algeria' },
  [PaddleSupportedCountries.EC]: { name: 'Ecuador' },
  [PaddleSupportedCountries.EE]: { name: 'Estonia' },
  [PaddleSupportedCountries.EG]: { name: 'Egypt' },
  [PaddleSupportedCountries.EH]: { name: 'Western Sahara' },
  [PaddleSupportedCountries.ER]: { name: 'Eritrea' },
  [PaddleSupportedCountries.ES]: { name: 'Spain' },
  [PaddleSupportedCountries.ET]: { name: 'Ethiopia' },
  [PaddleSupportedCountries.FI]: { name: 'Finland' },
  [PaddleSupportedCountries.FJ]: { name: 'Fiji' },
  [PaddleSupportedCountries.FK]: { name: 'Falkland Islands (Malvinas)' },
  [PaddleSupportedCountries.FM]: { name: 'Micronesia (Federated States of)' },
  [PaddleSupportedCountries.FO]: { name: 'Faroe Islands' },
  [PaddleSupportedCountries.FR]: { name: 'France' },
  [PaddleSupportedCountries.GA]: { name: 'Gabon' },
  [PaddleSupportedCountries.GB]: { name: 'United Kingdom' },
  [PaddleSupportedCountries.GD]: { name: 'Grenada' },
  [PaddleSupportedCountries.GE]: { name: 'Georgia' },
  [PaddleSupportedCountries.GF]: { name: 'French Guiana' },
  [PaddleSupportedCountries.GG]: { name: 'Guernsey' },
  [PaddleSupportedCountries.GH]: { name: 'Ghana' },
  [PaddleSupportedCountries.GI]: { name: 'Gibraltar' },
  [PaddleSupportedCountries.GL]: { name: 'Greenland' },
  [PaddleSupportedCountries.GM]: { name: 'Gambia' },
  [PaddleSupportedCountries.GN]: { name: 'Guinea' },
  [PaddleSupportedCountries.GP]: { name: 'Guadeloupe' },
  [PaddleSupportedCountries.GQ]: { name: 'Equatorial Guinea' },
  [PaddleSupportedCountries.GR]: { name: 'Greece' },
  [PaddleSupportedCountries.GS]: {
    name: 'South Georgia and the South Sandwich Islands',
  },
  [PaddleSupportedCountries.GT]: { name: 'Guatemala' },
  [PaddleSupportedCountries.GU]: { name: 'Guam' },
  [PaddleSupportedCountries.GW]: { name: 'Guinea-Bissau' },
  [PaddleSupportedCountries.GY]: { name: 'Guyana' },
  [PaddleSupportedCountries.HK]: { name: 'Hong Kong' },
  [PaddleSupportedCountries.HM]: { name: 'Heard Island and McDonald Islands' },
  [PaddleSupportedCountries.HN]: { name: 'Honduras' },
  [PaddleSupportedCountries.HR]: { name: 'Croatia' },
  [PaddleSupportedCountries.HU]: { name: 'Hungary' },
  [PaddleSupportedCountries.ID]: { name: 'Indonesia' },
  [PaddleSupportedCountries.IE]: { name: 'Ireland' },
  [PaddleSupportedCountries.IL]: { name: 'Israel' },
  [PaddleSupportedCountries.IM]: { name: 'Isle of Man' },
  [PaddleSupportedCountries.IN]: { name: 'India' },
  [PaddleSupportedCountries.IO]: { name: 'British Indian Ocean Territory' },
  [PaddleSupportedCountries.IQ]: { name: 'Iraq' },
  [PaddleSupportedCountries.IS]: { name: 'Iceland' },
  [PaddleSupportedCountries.IT]: { name: 'Italy' },
  [PaddleSupportedCountries.JE]: { name: 'Jersey' },
  [PaddleSupportedCountries.JM]: { name: 'Jamaica' },
  [PaddleSupportedCountries.JO]: { name: 'Jordan' },
  [PaddleSupportedCountries.JP]: { name: 'Japan' },
  [PaddleSupportedCountries.KE]: { name: 'Kenya' },
  [PaddleSupportedCountries.KG]: { name: 'Kyrgyzstan' },
  [PaddleSupportedCountries.KH]: { name: 'Cambodia' },
  [PaddleSupportedCountries.KI]: { name: 'Kiribati' },
  [PaddleSupportedCountries.KM]: { name: 'Comoros' },
  [PaddleSupportedCountries.KN]: { name: 'Saint Kitts and Nevis' },
  [PaddleSupportedCountries.KR]: { name: 'Korea (Republic of)' },
  [PaddleSupportedCountries.KW]: { name: 'Kuwait' },
  [PaddleSupportedCountries.KY]: { name: 'Cayman Islands' },
  [PaddleSupportedCountries.KZ]: { name: 'Kazakhstan' },
  [PaddleSupportedCountries.LA]: { name: "Lao People's Democratic Republic" },
  [PaddleSupportedCountries.LB]: { name: 'Lebanon' },
  [PaddleSupportedCountries.LC]: { name: 'Saint Lucia' },
  [PaddleSupportedCountries.LI]: { name: 'Liechtenstein' },
  [PaddleSupportedCountries.LK]: { name: 'Sri Lanka' },
  [PaddleSupportedCountries.LR]: { name: 'Liberia' },
  [PaddleSupportedCountries.LS]: { name: 'Lesotho' },
  [PaddleSupportedCountries.LT]: { name: 'Lithuania' },
  [PaddleSupportedCountries.LU]: { name: 'Luxembourg' },
  [PaddleSupportedCountries.LV]: { name: 'Latvia' },
  [PaddleSupportedCountries.MA]: { name: 'Morocco' },
  [PaddleSupportedCountries.MC]: { name: 'Monaco' },
  [PaddleSupportedCountries.MD]: { name: 'Moldova (Republic of)' },
  [PaddleSupportedCountries.ME]: { name: 'Montenegro' },
  [PaddleSupportedCountries.MF]: { name: 'Saint Martin (French part)' },
  [PaddleSupportedCountries.MG]: { name: 'Madagascar' },
  [PaddleSupportedCountries.MH]: { name: 'Marshall Islands' },
  [PaddleSupportedCountries.MK]: { name: 'North Macedonia' },
  [PaddleSupportedCountries.MN]: { name: 'Mongolia' },
  [PaddleSupportedCountries.MO]: { name: 'Macao' },
  [PaddleSupportedCountries.MP]: { name: 'Northern Mariana Islands' },
  [PaddleSupportedCountries.MQ]: { name: 'Martinique' },
  [PaddleSupportedCountries.MR]: { name: 'Mauritania' },
  [PaddleSupportedCountries.MS]: { name: 'Montserrat' },
  [PaddleSupportedCountries.MT]: { name: 'Malta' },
  [PaddleSupportedCountries.MU]: { name: 'Mauritius' },
  [PaddleSupportedCountries.MV]: { name: 'Maldives' },
  [PaddleSupportedCountries.MW]: { name: 'Malawi' },
  [PaddleSupportedCountries.MX]: { name: 'Mexico' },
  [PaddleSupportedCountries.MY]: { name: 'Malaysia' },
  [PaddleSupportedCountries.MZ]: { name: 'Mozambique' },
  [PaddleSupportedCountries.NA]: { name: 'Namibia' },
  [PaddleSupportedCountries.NC]: { name: 'New Caledonia' },
  [PaddleSupportedCountries.NE]: { name: 'Niger' },
  [PaddleSupportedCountries.NF]: { name: 'Norfolk Island' },
  [PaddleSupportedCountries.NG]: { name: 'Nigeria' },
  [PaddleSupportedCountries.NL]: { name: 'Netherlands' },
  [PaddleSupportedCountries.NO]: { name: 'Norway' },
  [PaddleSupportedCountries.NP]: { name: 'Nepal' },
  [PaddleSupportedCountries.NR]: { name: 'Nauru' },
  [PaddleSupportedCountries.NU]: { name: 'Niue' },
  [PaddleSupportedCountries.NZ]: { name: 'New Zealand' },
  [PaddleSupportedCountries.OM]: { name: 'Oman' },
  [PaddleSupportedCountries.PA]: { name: 'Panama' },
  [PaddleSupportedCountries.PE]: { name: 'Peru' },
  [PaddleSupportedCountries.PF]: { name: 'French Polynesia' },
  [PaddleSupportedCountries.PG]: { name: 'Papua New Guinea' },
  [PaddleSupportedCountries.PH]: { name: 'Philippines' },
  [PaddleSupportedCountries.PK]: { name: 'Pakistan' },
  [PaddleSupportedCountries.PL]: { name: 'Poland' },
  [PaddleSupportedCountries.PM]: { name: 'Saint Pierre and Miquelon' },
  [PaddleSupportedCountries.PN]: { name: 'Pitcairn' },
  [PaddleSupportedCountries.PR]: { name: 'Puerto Rico' },
  [PaddleSupportedCountries.PS]: { name: 'Palestine, State of' },
  [PaddleSupportedCountries.PT]: { name: 'Portugal' },
  [PaddleSupportedCountries.PW]: { name: 'Palau' },
  [PaddleSupportedCountries.PY]: { name: 'Paraguay' },
  [PaddleSupportedCountries.QA]: { name: 'Qatar' },
  [PaddleSupportedCountries.RE]: { name: 'Reunion' },
  [PaddleSupportedCountries.RO]: { name: 'Romania' },
  [PaddleSupportedCountries.RS]: { name: 'Serbia' },
  //   [PaddleSupportedCountries.RU]: { name: 'Russian Federation' },
  [PaddleSupportedCountries.RW]: { name: 'Rwanda' },
  [PaddleSupportedCountries.SA]: { name: 'Saudi Arabia' },
  [PaddleSupportedCountries.SB]: { name: 'Solomon Islands' },
  [PaddleSupportedCountries.SC]: { name: 'Seychelles' },
  [PaddleSupportedCountries.SE]: { name: 'Sweden' },
  [PaddleSupportedCountries.SG]: { name: 'Singapore' },
  [PaddleSupportedCountries.SH]: { name: 'Saint Helena' },
  [PaddleSupportedCountries.SI]: { name: 'Slovenia' },
  [PaddleSupportedCountries.SJ]: { name: 'Svalbard and Jan Mayen' },
  [PaddleSupportedCountries.SK]: { name: 'Slovakia' },
  [PaddleSupportedCountries.SL]: { name: 'Sierra Leone' },
  [PaddleSupportedCountries.SM]: { name: 'San Marino' },
  [PaddleSupportedCountries.SN]: { name: 'Senegal' },
  [PaddleSupportedCountries.SR]: { name: 'Suriname' },
  [PaddleSupportedCountries.ST]: { name: 'Sao Tome and Principe' },
  [PaddleSupportedCountries.SV]: { name: 'El Salvador' },
  [PaddleSupportedCountries.SX]: { name: 'Sint Maarten (Dutch part)' },
  [PaddleSupportedCountries.SZ]: { name: 'Eswatini' },
  [PaddleSupportedCountries.TC]: { name: 'Turks and Caicos Islands' },
  [PaddleSupportedCountries.TD]: { name: 'Chad' },
  [PaddleSupportedCountries.TF]: { name: 'French Southern Territories' },
  [PaddleSupportedCountries.TG]: { name: 'Togo' },
  [PaddleSupportedCountries.TH]: { name: 'Thailand' },
  [PaddleSupportedCountries.TJ]: { name: 'Tajikistan' },
  [PaddleSupportedCountries.TK]: { name: 'Tokelau' },
  [PaddleSupportedCountries.TL]: { name: 'Timor-Leste' },
  [PaddleSupportedCountries.TM]: { name: 'Turkmenistan' },
  [PaddleSupportedCountries.TN]: { name: 'Tunisia' },
  [PaddleSupportedCountries.TO]: { name: 'Tonga' },
  [PaddleSupportedCountries.TR]: { name: 'Turkey' },
  [PaddleSupportedCountries.TT]: { name: 'Trinidad and Tobago' },
  [PaddleSupportedCountries.TV]: { name: 'Tuvalu' },
  [PaddleSupportedCountries.TW]: { name: 'Taiwan, Province of China' },
  [PaddleSupportedCountries.TZ]: { name: 'Tanzania, United Republic of' },
  [PaddleSupportedCountries.UA]: { name: 'Ukraine' },
  [PaddleSupportedCountries.UG]: { name: 'Uganda' },
  [PaddleSupportedCountries.UM]: {
    name: 'United States Minor Outlying Islands',
  },
  [PaddleSupportedCountries.US]: { name: 'United States' },
  [PaddleSupportedCountries.UY]: { name: 'Uruguay' },
  [PaddleSupportedCountries.UZ]: { name: 'Uzbekistan' },
  [PaddleSupportedCountries.VA]: { name: 'Holy See' },
  [PaddleSupportedCountries.VC]: { name: 'Saint Vincent and the Grenadines' },
  [PaddleSupportedCountries.VG]: { name: 'British Virgin Islands' },
  [PaddleSupportedCountries.VI]: { name: 'United States Virgin Islands' },
  [PaddleSupportedCountries.VN]: { name: 'Viet Nam' },
  [PaddleSupportedCountries.VU]: { name: 'Vanuatu' },
  [PaddleSupportedCountries.WF]: { name: 'Wallis and Futuna' },
  [PaddleSupportedCountries.WS]: { name: 'Samoa' },
  [PaddleSupportedCountries.YT]: { name: 'Mayotte' },
  [PaddleSupportedCountries.ZA]: { name: 'South Africa' },
  [PaddleSupportedCountries.ZM]: { name: 'Zambia' },
};

export default translationsCountries;
