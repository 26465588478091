import { CheckInType, CoachingFormat } from '../../types/clients';

const translationsClient = {
  acceptedAt: 'Accepted At',
  action_needed_zero: 'No actions needed.',
  actions: 'Client Actions',
  activate: 'Activate Client',
  add: 'Add Client',
  age: 'Age',
  bodyweight: 'Bodyweight',
  charts: {
    bodyweight_empty: 'No bodyweight records.',
    bodyweight_subtitle: 'Last time measured: {{count, daysAgo}}',
    bodyweight_title: 'Bodyweight {{value}}',
    check_ins_empty: 'No check-ins recorded yet',
    check_ins_info: 'Last check-in: {{count, daysAgo}}',
    check_ins_subtitle: 'Explore sleep, energy & mood quality over time.',
    check_ins_title: 'Wellbeing check-ins',
  },
  check_in_type: {
    [CheckInType.Energy]: { label: 'Energy' },
    [CheckInType.Mood]: { label: 'Mood' },
    [CheckInType.Sleep]: { label: 'Sleep' },
    [CheckInType.Soreness]: { label: 'Soreness' },
  },
  check_ins: 'Check Ins',

  check_ins_one: 'Check In',
  coachingFormat: {
    [CoachingFormat.Blended]: { label: 'Both' },
    [CoachingFormat.FaceToFace]: { label: 'Face to face' },
    [CoachingFormat.Virtual]: { label: 'Online' },
    label: 'Coaching Format',
  },
  deactivate: 'Deactivate Client',
  delete: 'Delete Client',
  edit: 'Edit Client',
  empty_data: {
    cta: 'Add New Client',
    description:
      'There are currently no clients. Start by adding clients to begin managing their training plans.',
    title: 'No Clients Yet',
  },
  empty_search: {
    cta: 'Refine Your Search',
    description:
      'No clients match your search. Try adjusting your search terms.',
    title: 'No Clients Found',
  },
  height: 'Height',
  info: 'Client Info',
  label: 'Clients',
  label_one: 'Client',
  label_other: '{{count}} clients',
  label_zero: 'No Clients',
  status: {
    Active: {
      label: 'Active',
    },
    Inactive: {
      label: 'Inactive',
    },
    SignUpInstructionsSent: {
      label: 'Invited',
    },
  },
};

export default translationsClient;
