import { Identity, UserStatus } from '.';
import {
  ClientRoutineFull,
  ClientWorkout,
} from '../features/routine/@client/types';

export enum CoachingFormat {
  Blended = 'blended',
  FaceToFace = 'face_to_face',
  Virtual = 'virtual',
}

export interface Bodyweight {
  createdAt: string;
  id: string;
  value: number;
}

interface ClientCommon {
  coachId: null | string;
  coachingFormat: CoachingFormat | null;
  routinesInProgress: Pick<ClientRoutineFull, 'completedAt' | 'id' | 'name'>[];
  workoutCompletedLatest: ClientWorkout | null;
  workoutsInProgress: ClientWorkout[];
}

export interface Client extends ClientCommon {
  bodyWeights: { createdAt: string; id: string; value: number }[];
  businessId: null | string;
  checkIns: CheckInAttributes[];
  coachDetails: {
    avatarUrl: null | string;
    birthday: null | string;
    contactEmail: string;
    firstName: string;
    lastName: string;
    phone: null | string;

    website?: string;
  };
  facilityMember: {
    facilityId: string;
    userId: string;
  } | null;
  identity: Identity;
  inviteDetails: {
    acceptedAt: null | string;
  };
  status: UserStatus;
  userId: string;
}

export interface ClientListItem extends ClientCommon {
  acceptedAt: null | string;
  avatarUrl: null | string;
  email: string;
  facilityId: null | string;
  firstName: string;
  fullName: string;
  lastName: string;
  status: UserStatus;
  userId: string;
}

export enum CheckInType {
  Energy = 'energy',
  Mood = 'mood',
  Sleep = 'sleep',
  Soreness = 'soreness',
}

export type CheckInValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface CheckInAttributes
  extends Record<CheckInType, CheckInValue | null> {
  createdAt: string;
  id: string;
  userId?: string;
}

export interface UpdateClientAttributes {
  coachingFormat: CoachingFormat | null;
  firstName: string;
  lastName: string;
  status: UserStatus;
}
