/**
 * @file Contains setters for user cache
 */

import { queryClient } from '../../../../config/react-query';
import { QueryKey } from '../../../../constants/react-query';
import { User } from '../../types';

/**
 * Set data for the cached user
 * @param data The data we want to set
 */
export const setCurrentUser = (data: null | Partial<User> | User) => {
  queryClient.setQueryData<null | User>(QueryKey.User(), currentData => {
    if (currentData && data !== null) {
      return { ...currentData, ...data };
    }

    return currentData ?? (data as null | User);
  });
};
