const translationsBusiness = {
  admin_actions: 'Admin Actions',
  admin_delete: 'Delete Admin',
  admin_edit: 'Edit Admin',
  admin_info: 'Admin Info',
  admins: 'Admins',
  admins_add: 'Add Admin',
  admins_empty_data: {
    cta: 'Add New Admin',
    description:
      'There are no admins at the moment. To manage coaches, exercises, templates and clients, you can add new admins.',
    title: 'No Admins Added Yet!',
  },
  admins_empty_search: {
    cta: 'Change Search Filters',
    description:
      'No admins matched your search. You may want to try a different search.',
    title: 'No Admins Found',
  },
  admins_one: 'Admin',
  coach_assigned_zero: 'No Coach',
  coach_level: 'Level',
  coach_levels: 'Coach Levels',
  coaches: 'Coaches',
  coaches_actions: 'Coach Actions',
  coaches_activate: 'Activate Coach',
  coaches_add: 'Add Coach',
  coaches_deactivate: 'Deactivate Coach',
  coaches_delete: 'Delete Coach',
  coaches_edit: 'Edit Coach',
  coaches_empty_data: {
    cta: 'Add New Coach',
    description: 'You can add new coaches to help manage your trainees.',
    title: 'No Coaches Added Yet!',
  },
  coaches_empty_search: {
    cta: 'Modify Search Criteria',
    description:
      'No coaches match your search. You may want to try a different search',
    title: 'No Coaches Found',
  },
  coaches_info: 'Coach Info',
  coaches_one: 'Coach',
  facilities: 'Facilities',
  facilities_actions: 'Facility Actions',
  facilities_create: 'Create Facility',
  facilities_delete: 'Delete Facility',
  facilities_edit: 'Edit Facility',
  facilities_one: 'Facility',
  facility_assigned_zero: 'No Facility',
  members: 'Members',
  people: 'People',
  people_create: 'Create User',
  people_create_admin: 'Create Admin',
  people_create_client: 'Create Client',
  people_create_coach: 'Create Coach',
  people_edit: 'Edit User',
};

export default translationsBusiness;
