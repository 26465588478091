/**
 * @file Hook that matches current path with out route paths to determine which page url is
 */
import { matchRoutes, useLocation } from 'react-router-dom';

import * as PATHS from '../paths/paths';

/**
 * All routes to be matched
 */
const routesToMatch = Object.values(PATHS).map(route => ({ path: route }));

/**
 * Hook that determines the current rendered router path
 * @returns Current path string
 */
const useCurrentPath = (): null | string => {
  const location = useLocation();

  const matches = matchRoutes(routesToMatch, location);

  if (!matches?.[0].route.path) {
    return null;
  }

  return matches[0].route.path;
};

export default useCurrentPath;
