import { AxiosError, isAxiosError } from 'axios';

import { ApiError } from '../constants/errors/api-errors';
import { CognitoError } from '../constants/errors/cognito';

/**
 * Get error message key
 * @param error        Should accept all errors
 * @param defaultError The error we want to return if it does not match any handled errors
 * @returns            An valid key for translations @todo - needs implementation
 */
export const getApiErrorCode = (
  error: never | unknown,
  defaultError: ApiError = ApiError.Default,
): ApiError => {
  if (error instanceof AxiosError && error?.response?.data?.code) {
    const msg = error?.response?.data?.code;

    return msg in ApiError ? (msg as ApiError) : defaultError;
  }

  console.error('UNRECOGNIZED_ERROR');

  return defaultError;
};

type CognitoErrorType = {
  code: CognitoError;
  error?: { message: string; name?: CognitoError };
  name?: CognitoError;
};

/**
 * Extracts the error code from the cognito error object
 * @param err          Any error object that comes from cognito
 * @param defaultError The default error message to display if none error found
 * @returns            The cognito error key we can handle in the app
 */
export const getCognitoErrorMessage = (
  err: never | unknown,
  defaultError = CognitoError.CognitoDefaultError,
): CognitoError => {
  const theError = err as AxiosError | CognitoErrorType;

  if (isAxiosError(theError)) {
    const theData = theError.response?.data as CognitoErrorType;

    return theData?.error?.name ?? defaultError;
  }
  return (
    theError?.error?.name ??
    theError?.code ??
    (theError as CognitoErrorType)?.name ??
    defaultError
  );
};

/**
 * Get whether an error object is NewPasswordRequired error from cognito
 * @param err Any error object
 * @returns   Whether the error is a cognito error which requires a new password to be set
 */
export const isRequireNewPassword = (err: never | unknown): boolean => {
  return (
    (err as { message: string }).message.search(
      CognitoError.PasswordResetRequiredException,
    ) > -1
  );
};
