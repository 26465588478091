/**
 * @file We use ids to identify buttons
 * The translations can be found in {@link file://./../i18n/en/buttons.ts}
 */

/**
 * Ids of buttons we use to identify translations, tooltips, etc..
 */
export const ButtonId = {
  Actions: 'Button_Actions',
  Apply: 'Button_Apply',
  Back: 'Button_Back',
  Cancel: 'Button_Cancel',
  ClientCreate: 'Button_ClientCreate',
  ClientDelete: 'Button_ClientDelete',
  ClientEdit: 'Button_ClientEdit',
  Close: 'Button_Close',
  CommentAdd: 'Button_CommentAdd',
  CommentDelete: 'Button_CommentDelete',
  CommentsAdd: 'Button_CommentsAdd',
  Continue: 'Button_Continue',
  Create: 'Button_Create',
  CycleCreate: 'Button_CycleCreate',
  CycleDelete: 'Button_CycleDelete',
  CycleEdit: 'Button_CycleEdit',
  Delete: 'Button_Delete',
  Duplicate: 'Button_Duplicate',
  Edit: 'Button_Edit',
  ExerciseChange: 'Button_ExerciseChange',
  ExerciseCreate: 'Button_ExerciseCreate',
  ExerciseDelete: 'Button_ExerciseDelete',
  ExerciseEdit: 'Button_ExerciseEdit',
  Logout: 'Button_Logout',
  Move: 'Button_Move',
  PasswordChange: 'Button_PasswordChange',
  PasswordForgot: 'Button_PasswordForgot',
  PasswordReset: 'Button_PasswordReset',
  ResendVerificationCode: 'Button_ResendVerificationCode',
  Reset: 'Button_Reset',
  RoutineCreate: 'Button_RoutineCreate',
  RoutineDelete: 'Button_RoutineDelete',
  RoutineEdit: 'Button_RoutineEdit',
  RoutineInfo: 'Button_RoutineInfo',
  RoutineMove: 'Button_RoutineMove',
  Save: 'Button_Save',
  SeriesCreate: 'Button_SeriesCreate',
  SetAdd: 'Button_SetAdd',
  SetsBuilder: 'Button_SetsBuilder',
  SignIn: 'Button_SignIn',
  SignUp: 'Button_SignUp',
  Submit: 'Button_Submit',
  TemplateRoutineCreate: 'Button_TemplateRoutineCreate',
  TemplateWorkoutCreate: 'Button_TemplateWorkoutCreate',
  UpdatePlan: 'Button_UpdatePlan',
  WorkoutAdd: 'Button_WorkoutAdd',
  WorkoutBuilder: 'Button_WorkoutBuilder',
  WorkoutCompare: 'Button_WorkoutCompare',
  WorkoutCreate: 'Button_WorkoutCreate',
  WorkoutDelete: 'Button_WorkoutDelete',
  WorkoutDuplicate: 'Button_WorkoutDuplicate',
  WorkoutEdit: 'Button_WorkoutEdit',
  WorkoutPrepare: 'Button_WorkoutPrepare',
} as const;

export type ButtonId = (typeof ButtonId)[keyof typeof ButtonId];
