export interface Timestamps {
  createdAt?: string;
  deletedAt?: null | string;
  updatedAt?: string;
}

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export enum MediaType {
  Avatar = 'Avatar',
  BusinessAvatar = 'BusinessAvatar',
  BusinessLogo = 'BusinessLogo',
  BusinessLogoBlack = 'BusinessLogoBlack',
  BusinessLogoWhite = 'BusinessLogoWhite',
  ExerciseThumbnail = 'ExerciseThumbnail',
  ExerciseVideo = 'ExerciseVideo',
}

export enum AlertLevel {
  Critical = 'critical',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum ProxyParam {
  CheckoutSuccess = 'checkout-success',
}

export enum ErrorParam {
  Forbidden = 'forbidden',
  NotFound = 'not-found',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  SignUpInstructionsSent = 'SignUpInstructionsSent',
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
}

export interface Identity {
  avatarUrl: null | string;
  birthday: null | string;
  contactEmail: string;
  firstName: string;
  gender: Gender | null;
  height: null | number;
  lastName: string;
  phone: null | string;
}

export type IdentityList = Pick<
  Identity,
  'avatarUrl' | 'contactEmail' | 'firstName' | 'lastName'
>;
