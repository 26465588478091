const QueryKey = {
  Business: () => ['Business'],
  BusinessAdminUser: (userId: null | string | undefined) =>
    userId ? ['BusinessAdminUser', userId] : ['BusinessAdminUser'],
  BusinessCoachRanks: () => ['BusinessCoachRanks'],
  BusinessCoachUser: (userId: null | string | undefined) =>
    userId ? ['BusinessCoachUser', userId] : ['BusinessCoachUser'],

  Checkout: (priceId: null | string) => ['Checkout', priceId],
  Client: (id: null | string) => ['Client', id],
  ClientRoutine: (routineId: null | string) => ['ClientRoutine', routineId],
  ClientRoutines: (clientId: null | string) => ['ClientRoutines', clientId],
  Clients: () => ['Clients'],
  ClientWorkout: (workoutId: null | string) => ['ClientWorkout', workoutId],

  Exercise: (id: null | string) => ['Exercise', id],
  Exercises: () => ['Exercises'],

  Locale: () => ['Locale'],
  SubscriptionProducts: () => ['SubscriptionProducts'],

  Subscriptions: () => ['Subscriptions'],
  SubscriptionUpdatePreview: (
    subscriptionId: string | undefined,
    priceId: string | undefined,
  ) => ['SubscriptionUpdatePreview', subscriptionId, priceId],

  TemplateRoutine: (id: null | string) => ['TemplateRoutine', id],
  TemplateRoutines: () => ['TemplateRoutines'],
  TemplateWorkout: (id: null | string) => ['TemplateWorkout', id],
  TemplateWorkouts: () => ['TemplateWorkouts'],
  User: () => ['User'],
  UserData: () => ['UserData'],
};

export const REACT_QUERY__REFETCH_OPTIONS__ON = {
  refetchOnMount: true,
  refetchOnReconnect: true,
  refetchOnWindowFocus: true,
} as const;

export type QueryRefetchOptions = Partial<
  {
    enabled: boolean;
    refetchInterval: number;
    staleTime: number;
  } & typeof REACT_QUERY__REFETCH_OPTIONS__ON
>;

export { QueryKey };
