/**
 * @file Contains keys for mutations
 */

export const MUTATION__CLIENT_WORKOUT__DUPLICATE =
  'MUTATION__CLIENT_WORKOUT__DUPLICATE';

export const MUTATION__TEMPLATE_WORKOUT__DUPLICATE =
  'MUTATION__TEMPLATE_WORKOUT__DUPLICATE';

export enum MutationUpdate {
  ClientWorkoutDuplicate = 'MutationUpdate_ClientWorkoutDuplicate',
  ClientWorkoutReset = 'MutationUpdate_ClientWorkoutReset',
  ClientWorkoutSkip = 'MutationUpdate_ClientWorkoutSkip',
  ClientWorkoutUnSkip = 'MutationUpdate_ClientWorkoutUnSkip',
  Exercise = 'MutationUpdate_Exercise',
  SubscriptionActionCancel = 'MutationUpdate_SubscriptionActionCancel',
  SubscriptionActionPause = 'MutationUpdate_SubscriptionActionPause',
  SubscriptionActionResume = 'MutationUpdate_SubscriptionActionResume',
  SubscriptionUpdate = 'MutationUpdate_Subscription',
}

export type MutationUpdateConfirm =
  | MutationUpdate.ClientWorkoutReset
  | MutationUpdate.ClientWorkoutSkip
  | MutationUpdate.SubscriptionActionCancel
  | MutationUpdate.SubscriptionActionPause
  | MutationUpdate.SubscriptionActionResume;

export enum MutationDelete {
  BusinessAdmin = 'MutationDelete_BusinessAdmin',
  BusinessCoach = 'MutationDelete_BusinessCoach',
  BusinessCoachRank = 'MutationDelete_BusinessCoachRank',
  Client = 'MutationDelete_Client',
  ClientRoutine = 'MutationDelete_ClientRoutine',
  ClientWorkout = 'MutationDelete_ClientWorkout',
  Exercise = 'MutationDelete_Exercise',
  RoutineCycle = 'MutationDelete_RoutineCycle',
  Subscription = 'MutationDelete_Subscription',
  TemplateRoutine = 'MutationDelete_TemplateRoutine',
  TemplateWorkout = 'MutationDelete_TemplateWorkout',
}
