/**
 * @file We use ids for toast contents.
 * The contents can be found in {@see file://./../i18n/en/toasts.ts}
 */

/**
 * Ids for success toasts
 */
export enum ToastSuccessId {
  BusinessAdminCreate = 'Success_BusinessAdminCreate',
  BusinessAdminDelete = 'Success_BusinessAdminDelete',
  BusinessCoachCreate = 'Success_BusinessCoachCreate',
  BusinessCoachDelete = 'Success_BusinessCoachDelete',
  BusinessFacilityCreate = 'Success_BusinessFacilityCreate',
  BusinessFacilityDelete = 'Success_BusinessFacilityDelete',
  ClientCreate = 'Success_ClientCreate',
  ClientDelete = 'Success_ClientDelete',
  ClientRoutineGenerated = 'Success_ClientRoutineGenerated',
  PasswordChange = 'Success_PasswordChange',
  PasswordForgot = 'Success_PasswordForgot',
  PasswordReset = 'Success_PasswordReset',
  ResendVerificationCode = 'Success_ResendVerificationCode',
  SignUp = 'Success_SignUp',
  SubscriptionUpdated = 'Success_SubscriptionUpdated',
  TemplateRoutineGenerated = 'Success_TemplateRoutineGenerated',
  TemplateWorkoutGenerated = 'Success_TemplateWorkoutGenerated',
}

/**
 * Ids for error toasts
 */
export enum ToastErrorId {
  AvatarUploadLarge = 'Error_AvatarUploadLarge',
  General = 'Error_General',
  LoginUserClientForbidden = 'Error_LoginUserClientForbidden',
  PasswordChange = 'Error_PasswordChange',
  ResendVerificationCode = 'Error_ResendVerificationCode',
  SubscriptionUpdate = 'Error_SubscriptionUpdate',
}

/**
 * Ids for warning toasts
 */
export enum ToastWarningId {
  InternetConnection = 'Warning_InternetConnection',
}

/**
 * Ids for info toasts
 */
export enum ToastInfoId {
  InternetConnection = 'Info_InternetConnection',
}
