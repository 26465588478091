import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosError } from 'axios';

import { ApiError } from '../constants/errors/api-errors';
import { refreshSession, setUser } from '../features/user/utils/cognito';
import { getApiErrorCode } from '../utils/errors';
import envs from './envs';

export interface ApiResponse<T> {
  data: T;
  message: string;
  statusCode: number;
}

export type ApiResponseError = AxiosError<
  unknown,
  { code: ApiError; details?: any; message: string }
>;

const headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
  baseURL: envs.apiBaseUrl,
  headers,
});

axiosInstance.interceptors.request.use(async config => {
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken;
  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token.toString()}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (
      getApiErrorCode(error) === ApiError.AuthTokenExpired &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        await refreshSession();
      } catch (err) {
        console.log('HTTP SET USER NULL');
        delete originalRequest.headers.Authorization;
        setUser(null, true);
      }

      return axiosInstance(originalRequest);
    } else if (error?.response?.status === 401) {
      if (originalRequest?._retry === true) {
        console.log('61 Line HTTP SET USER NULL');
        setUser(null, true);
      }
    }

    return Promise.reject(error);
  },
);

const http = axiosInstance;

export default http;
