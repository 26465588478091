import {
  PATH_FRAGMENT__ADMINS,
  PATH_FRAGMENT__BUSINESS,
  PATH_FRAGMENT__CLIENT_ID,
  PATH_FRAGMENT__CLIENTS,
  PATH_FRAGMENT__COACH_ID,
  PATH_FRAGMENT__COACHES,
  PATH_FRAGMENT__ERROR,
  PATH_FRAGMENT__ERROR_TYPE,
  PATH_FRAGMENT__EXERCISES,
  PATH_FRAGMENT__FACILITIES,
  PATH_FRAGMENT__FACILITY_ID,
  PATH_FRAGMENT__HOME,
  PATH_FRAGMENT__LIBRARY,
  PATH_FRAGMENT__ME,
  PATH_FRAGMENT__ONBOARDING,
  PATH_FRAGMENT__PRODUCT_TYPE,
  PATH_FRAGMENT__PROFILE,
  PATH_FRAGMENT__PROXY,
  PATH_FRAGMENT__PROXY_TYPE,
  PATH_FRAGMENT__ROOT,
  PATH_FRAGMENT__ROUTINE_ID,
  PATH_FRAGMENT__ROUTINES,
  PATH_FRAGMENT__SETTINGS,
  PATH_FRAGMENT__SUBSCRIPTIONS,
  PATH_FRAGMENT__WORKOUT_ID,
  PATH_FRAGMENT__WORKOUTS,
} from './path-fragments';

/**
 * /routines/:routineId
 */
const COMMON__ROUTINES__BY_ID =
  PATH_FRAGMENT__ROUTINES + PATH_FRAGMENT__ROUTINE_ID;

/**
 * /workouts/:workoutId
 */
const COMMON__WORKOUTS__BY_ID =
  PATH_FRAGMENT__WORKOUTS + PATH_FRAGMENT__WORKOUT_ID;

/**
 * /coaches/:coachId
 */
const COMMON__COACHES__BY_ID = PATH_FRAGMENT__COACHES + PATH_FRAGMENT__COACH_ID;

/**
 * /admins/:adminId
 */
const COMMON__CLIENTS__BY_ID =
  PATH_FRAGMENT__CLIENTS + PATH_FRAGMENT__CLIENT_ID;

/**
 * /
 */
export const PATH__ROOT = PATH_FRAGMENT__ROOT;

/**
 * /home
 */
export const PATH__HOME = PATH_FRAGMENT__HOME;

/**
 * /me
 */
export const PATH__ME = PATH_FRAGMENT__ME;

/**
 * /me/routines/:routineId
 */
export const PATH__ME__ROUTINE__BY_ID = PATH__ME + COMMON__ROUTINES__BY_ID;

/**
 * /me/routines/:routineId/workouts/:workoutId
 */
export const PATH__ME__WORKOUT__BY_ID =
  PATH__ME__ROUTINE__BY_ID + COMMON__WORKOUTS__BY_ID;

/**
 * /clients
 */
export const PATH__CLIENTS = PATH_FRAGMENT__CLIENTS;

/**
 * /clients/:clientId
 */
export const PATH__CLIENTS__BY_ID = PATH__CLIENTS + PATH_FRAGMENT__CLIENT_ID;

/**
 * /clients/:clientId/routines/:routineId
 */
export const PATH__CLIENTS__BY_ID__ROUTINE__BY_ID =
  PATH__CLIENTS__BY_ID + COMMON__ROUTINES__BY_ID;

/**
 * /clients/:clientId/routines/:routineId/workouts/:workoutId
 */
export const PATH__CLIENTS__WORKOUT__BY_ID =
  PATH__CLIENTS__BY_ID__ROUTINE__BY_ID + COMMON__WORKOUTS__BY_ID;

/**
 * /business
 */
export const PATH__BUSINESS = PATH_FRAGMENT__BUSINESS;

/**
 * /business/clients
 */
export const PATH__BUSINESS__CLIENTS = PATH__BUSINESS + PATH__CLIENTS;

/**
 * /business/clients/:clientId
 */
export const PATH__BUSINESS__CLIENTS__BY_ID =
  PATH__BUSINESS + PATH__CLIENTS__BY_ID;

/**
 * /business/clients/:clientId/routines/:routineId
 */
export const PATH__BUSINESS__CLIENTS__BY_ID__ROUTINE__BY_ID =
  PATH__BUSINESS + PATH__CLIENTS__BY_ID__ROUTINE__BY_ID;

/**
 * /business/clients/:clientId/routines/:routineId/workouts/:workoutId
 */
export const PATH__BUSINESS__CLIENTS__WORKOUT =
  PATH__BUSINESS + PATH__CLIENTS__WORKOUT__BY_ID;

/**
 * /business/coaches
 */
export const PATH__BUSINESS__COACHES = PATH__BUSINESS + PATH_FRAGMENT__COACHES;

/**
 * /business/coaches/:coachId
 */
export const PATH__BUSINESS__COACHES__BY_ID =
  PATH__BUSINESS__COACHES + PATH_FRAGMENT__COACH_ID;

/**
 * /business/coaches/:coachId/clients/:clientId
 */
export const PATH__BUSINESS__COACH_CLIENT__BY_ID =
  PATH__BUSINESS__COACHES__BY_ID + COMMON__CLIENTS__BY_ID;

/**
 * /business/coaches/:coachId/clients/:clientId/routines/:routineId
 */
export const PATH__BUSINESS__COACH_CLIENT_ROUTINE__BY_ID =
  PATH__BUSINESS__COACHES__BY_ID + PATH__CLIENTS__BY_ID__ROUTINE__BY_ID;

/**
 * /business/coaches/:coachId/clients/:clientId/routines/:routineId/workouts/:workoutId
 */
export const PATH__BUSINESS__COACH_CLIENT_WORKOUT__BY_ID =
  PATH__BUSINESS__COACHES__BY_ID + PATH__CLIENTS__WORKOUT__BY_ID;

/**
 * /business/admins
 */
export const PATH__BUSINESS__ADMINS = PATH__BUSINESS + PATH_FRAGMENT__ADMINS;

/**
 * /business/facilities
 */
export const PATH__BUSINESS__FACILITIES =
  PATH__BUSINESS + PATH_FRAGMENT__FACILITIES;

/**
 * /business/facilities/:facilityId
 */
export const PATH__BUSINESS__FACILITIES__BY_ID =
  PATH__BUSINESS__FACILITIES + PATH_FRAGMENT__FACILITY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId
 */
export const PATH__BUSINESS__FACILITY_COACH__BY_ID =
  PATH__BUSINESS__FACILITIES__BY_ID + COMMON__COACHES__BY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId/clients/:clientId
 */
export const PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID =
  PATH__BUSINESS__FACILITY_COACH__BY_ID + COMMON__CLIENTS__BY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId/clients/:clientId/routines/:routineId
 */
export const PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID =
  PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID + COMMON__ROUTINES__BY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId/clients/:clientId/routines/:routineId/workouts/:workoutId
 */
export const PATH__BUSINESS__FACILITY_COACH_CLIENT_WORKOUT__BY_ID =
  PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID +
  COMMON__WORKOUTS__BY_ID;

/**
 * /business/facilities/:facilityId/clients/:clientId
 */
export const PATH__BUSINESS__FACILITY_CLIENT__BY_ID =
  PATH__BUSINESS__FACILITIES__BY_ID + COMMON__CLIENTS__BY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId/clients/:clientId/routines/:routineId
 */
export const PATH__BUSINESS__FACILITY_CLIENT_ROUTINE__BY_ID =
  PATH__BUSINESS__FACILITY_CLIENT__BY_ID + COMMON__ROUTINES__BY_ID;

/**
 * /business/facilities/:facilityId/coaches/:coachId/clients/:clientId/routines/:routineId/workouts/:workoutId
 */
export const PATH__BUSINESS__FACILITY_CLIENT_WORKOUT__BY_ID =
  PATH__BUSINESS__FACILITY_CLIENT_ROUTINE__BY_ID + COMMON__WORKOUTS__BY_ID;

/**
 * /library
 */
export const PATH__LIBRARY = PATH_FRAGMENT__LIBRARY;

/**
 * /library/exercises
 */
export const PATH__LIBRARY__EXERCISES =
  PATH__LIBRARY + PATH_FRAGMENT__EXERCISES;

/**
 * /library/routines
 */
export const PATH__LIBRARY__TEMPLATE_ROUTINES =
  PATH__LIBRARY + PATH_FRAGMENT__ROUTINES;

/**
 * /library/routines/:routineId
 */
export const PATH__LIBRARY__TEMPLATE_ROUTINE_FULL =
  PATH__LIBRARY__TEMPLATE_ROUTINES + PATH_FRAGMENT__ROUTINE_ID;

/**
 * /library/routines/:routineId/workouts/:workoutId
 */
export const PATH__LIBRARY__TEMPLATE_ROUTINE_WORKOUT =
  PATH__LIBRARY__TEMPLATE_ROUTINE_FULL + COMMON__WORKOUTS__BY_ID;

/**
 * /library/workouts
 */
export const PATH__LIBRARY__TEMPLATE_WORKOUTS =
  PATH__LIBRARY + PATH_FRAGMENT__WORKOUTS;

/**
 * /library/workouts/:workoutId
 */
export const PATH__LIBRARY__TEMPLATE_WORKOUT_FULL =
  PATH__LIBRARY__TEMPLATE_WORKOUTS + PATH_FRAGMENT__WORKOUT_ID;

/**
 * /onboarding
 */
export const PATH__ONBOARDING = PATH_FRAGMENT__ONBOARDING;

/**
 * /onboarding/:productType
 */
export const PATH__ONBOARDING__PRODUCT =
  PATH__ONBOARDING + PATH_FRAGMENT__PRODUCT_TYPE;

/**
 * /error
 */
export const PATH__ERROR = PATH_FRAGMENT__ERROR;

/**
 * /error/:error
 */
export const PATH__ERROR__TYPE = PATH__ERROR + PATH_FRAGMENT__ERROR_TYPE;

/**
 * /proxy
 */
export const PATH__PROXY = PATH_FRAGMENT__PROXY;

/**
 * /proxy/:proxyType
 */
export const PATH__PROXY_TYPE = PATH__PROXY + PATH_FRAGMENT__PROXY_TYPE;

/**
 * /settings
 */
export const PATH__SETTINGS = PATH_FRAGMENT__SETTINGS;

/**
 * /settings/profile
 */
export const PATH__SETTINGS__PROFILE = PATH__SETTINGS + PATH_FRAGMENT__PROFILE;

/**
 * /settings/subscriptions
 */
export const PATH__SETTINGS__SUBSCRIPTIONS =
  PATH__SETTINGS + PATH_FRAGMENT__SUBSCRIPTIONS;

/**
 * /settings/business
 */
export const PATH__SETTINGS__BUSINESS =
  PATH__SETTINGS + PATH_FRAGMENT__BUSINESS;
