import { AlertId } from '../../constants/alert';

const translationsAlert: Record<
  AlertId,
  { description: string; title: string }
> = {
  [AlertId.NotSubscribed]: {
    description:
      'You are not subscribed. Sign up to enjoy all the features of the app.',
    title: 'Not Subscribed',
  },
  [AlertId.SubscriptionCanceled]: {
    description:
      'Your subscription has been canceled. Please renew to continue using the service.',
    title: 'Subscription Canceled',
  },
  [AlertId.SubscriptionPastDue]: {
    description:
      'Your subscription payment is overdue. Update your payment method to avoid service interruptions.',
    title: 'Subscription Past Due',
  },
  [AlertId.SubscriptionPaused]: {
    description:
      'Your subscription has been paused. Resume it to regain access to all features.',
    title: 'Subscription Paused',
  },
  [AlertId.SubscriptionTrialEndingSoon]: {
    description: `Your trial period is ending at {{date}}.`,
    title: 'Trial Ending Soon',
  },
};

export default translationsAlert;
