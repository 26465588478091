/** @file Contains translations for api errors */
import { ApiError } from '../../constants/errors/api-errors';
import { CognitoError } from '../../constants/errors/cognito';

interface Props {
  text: string;
  title: string;
}

const translationCognitoErrors: Record<CognitoError, Props> = {
  CodeMismatchException: {
    text: 'The verification code provided does not match the one sent to your email or phone.',
    title: 'Code Mismatch',
  },
  CognitoDefaultError: {
    text: 'An error occurred while processing your request. Please try again.',
    title: 'Something Went Wrong',
  },
  [CognitoError.UserAlreadyAuthenticatedException]: {
    text: 'User Already Authenticated',
    title: 'User Already Authenticated',
  },
  ExpiredCodeException: {
    text: 'The verification code you provided has expired. Please request a new code.',
    title: 'Expired Code',
  },
  InternalErrorException: {
    text: 'An internal error occurred while processing your request. Please try again later.',
    title: 'Internal Error',
  },
  InvalidPasswordException: {
    text: 'The password you provided is invalid. Please check your password and try again.',
    title: 'Invalid Password',
  },
  LimitExceededException: {
    text: 'The request exceeded a limit. Please try again later.',
    title: 'Limit Exceeded',
  },
  NotAuthorizedException: {
    text: 'You are not authorized to perform this action. Please sign in with appropriate credentials.',
    title: 'Not Authorized',
  },
  PasswordResetRequiredException: {
    text: 'You must reset your password before you can continue.',
    title: 'Password Reset Required',
  },
  TooManyRequestsException: {
    text: 'Too many requests have been made. Please try again later.',
    title: 'Too Many Requests',
  },
  UsernameExistsException: {
    text: 'The provided username already exists. Please choose a different username.',
    title: 'Username Exists',
  },
  UserNotFoundException: {
    text: 'The user does not exist. Please sign up or check the username.',
    title: 'User Not Found',
  },
  UserUnAuthenticatedException: {
    text: 'Please log in to continue. Your session may have expired.',
    title: 'Authentication Required',
  },
};

/** @todo - Remove Partial and handle all errors */
// @ts-ignore
const translationApiErrors: Record<ApiError, Props> = {
  [ApiError.BadRequest]: {
    text: 'The request could not be processed. Please check your input and try again.',
    title: 'Bad Request',
  },
  [ApiError.ClientBelongsToAnotherCoach]: {
    text: 'You can not add this client right now. Please try again later.',
    title: 'Action denied!',
  },
  [ApiError.Default]: {
    text: 'Something went wrong with this request.',
    title: 'Oops!',
  },
  [ApiError.ExerciseDeleteUsedInExerciseInstructions]: {
    text: 'This exercise is still used in some workouts. Please delete workouts or change the exercise.',
    title: 'Attention!',
  },
  [ApiError.InvalidLambdaException]: {
    text: 'Something went wrong with this request.',
    title: 'Oops!',
  },
  [ApiError.RequestValidation]: {
    text: 'Some required fields are missing or incorrect. Double-check and submit again.',
    title: 'Request Failed',
  },
  [ApiError.SubscriptionInactive]: {
    text: 'To have full write access, you need to be subscribed.',
    title: 'Subscription Required',
  },
  [ApiError.UserInactive]: {
    text: 'Your status is currently set to inactive and your account has only read access.',
    title: 'User Status Inactive',
  },
} as Record<ApiError, Props>;

const translationsApiErrors = {
  ...translationCognitoErrors,
  ...translationApiErrors,
};

export default translationsApiErrors;
