/**
 * @file We use ids to identify form fields
 * The translations can be found in {@link file://./../../i18n/en/form-field.ts}
 */

/**
 * Ids of form fields we use to identify translations, tooltips, etc..
 */

export enum FieldId {
  Admins = 'Field_Admins',
  Birthday = 'Field_Birthday',
  BodyWeightPercentage = 'Field_BodyWeightPercentage',
  BusinessCoachRank = 'Field_BusinessCoachRank',
  BusinessName = 'Field_BusinessName',
  ClientsSearch = 'Field_ClientsSearch',
  Coach = 'Field_Coach',
  Coaches = 'Field_Coaches',
  CoachesSearch = 'Field_CoachesSearch',
  CoachingFormat = 'Field_CoachingFormat',
  Comments = 'Field_Comments',
  Complexity = 'Field_Complexity',
  ConfirmPassword = 'Field_ConfirmPassword',
  CountryCode = 'Field_CountryCode',
  CycleName = 'Field_CycleName',
  Description = 'Field_Description',
  Difficulty = 'Field_Difficulty',
  Email = 'Field_Email',
  ExecutionType = 'Field_ExecutionType',
  ExerciseSearch = 'Field_ExerciseSearch',
  ExerciseType = 'Field_ExerciseType',
  ExerciseViewSelect = 'Field_ExerciseViewSelect',
  ExternalUrl = 'Field_ExternalUrl',
  Facilities = 'Field_Facilities',
  Facility = 'Field_Facility',
  FirstName = 'Field_FirstName',
  Gender = 'Field_Gender',
  Height = 'Field_Height',
  Instruction = 'Field_Instruction',
  IsUnilateral = 'Field_IsUnilateral',
  LastName = 'Field_LastName',
  Login = 'Field_Login',
  Name = 'Field_Name',
  NewPassword = 'Field_NewPassword',
  OldPassword = 'Field_OldPassword',
  Password = 'Field_Password',
  PostalCode = 'Field_PostalCode',
  PrimaryGoals = 'Field_PrimaryGoals',
  PrimaryMuscles = 'Field_PrimaryMuscles',
  Region = 'Field_Region',
  RestDetails = 'Field_RestDetails',
  RestHours = 'Field_RestHours',
  SecondaryGoals = 'Field_SecondaryGoals',
  SecondaryMuscles = 'Field_SecondaryMuscles',
  SelectClient = 'Field_SelectClient',
  SelectExercise = 'Field_SelectExercise',
  SetComments = 'Field_SetComments',
  SetIsOptional = 'Field_SetSetIsOptional',
  SetRest = 'Field_SetRest',
  SetRpe = 'Field_SetRpe',
  SetSets = 'Field_SetSets',
  SetTempo = 'Field_SetTempo',
  SetVolume = 'Field_SetVolume',
  SetVolumeUnit = 'Field_SetVolumeUnit',
  SetWeight = 'Field_SetWeight',
  SubscriptionBillingInterval = 'Field_SubscriptionBillingInterval',
  SubscriptionPlanType = 'Field_SubscriptionPlanType',
  SubscriptionPlanVariant = 'Field_SubscriptionPlanVariant',
  TaxIdentifier = 'Field_TaxIdentifier',
  TemplateSearch = 'Field_TemplateSearch',
  TemporaryPassword = 'Field_TemporaryPassword',
  Terms = 'Field_Terms',
  Thumbnail = 'Field_Thumbnail',
  Username = 'Field_Username',
  Variation = 'Field_Variation',
  Video = 'Field_Video',
  VolumeUnits = 'Field_VolumeUnits',
  WorkoutCompareColumnsFilter = 'Field_WorkoutCompareColumnsFilter',
  WorkoutCompareSelect = 'Field_WorkoutCompareSelect',
}
