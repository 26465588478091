import { createTheme } from '@mantine/core';
import { themeToVars } from '@mantine/vanilla-extract';

export const mantineTheme = createTheme({
  colors: {
    branding: [
      '#E2FBFF',
      '#CDF2FF',
      '#9CE2FF',
      '#67D2FE',
      '#41C5FD',
      '#2BBCFD',
      '#1AB8FE',
      '#00A1E3',
      '#008FCB',
      '#007CB4',
    ],
  },
  components: {
    Anchor: {
      defaultProps: {
        underline: 'never',
      },
    },
    Button: {
      defaultProps: {
        radius: 'md',
      },
    },
    Group: {
      defaultProps: {
        wrap: 'nowrap',
      },
    },
    ListItem: {
      defaultProps: {
        styles: {
          itemLabel: { width: '100%' },
          itemWrapper: { width: '100%' },
        },
      },
    },
    Modal: {
      defaultProps: {
        styles: {
          content: {
            maxHeight: '80dvh',
            overflow: 'auto',
          },
          header: {
            alignItems: 'flex-start',
          },
          root: { overflow: 'hidden' },
        },
        transitionProps: {
          exitDuration: 0,
        },
      },
    },
    Paper: {
      defaultProps: {
        radius: 'md',
      },
    },
    PasswordInput: {
      defaultProps: {
        radius: 'md',
      },
    },
    SegmentedControl: {
      defaultProps: {},
    },
    TextInput: {
      defaultProps: {
        radius: 'md',
      },
    },
  },
  primaryColor: 'branding',
});

export const screenMin = (width: number | string) =>
  `screen and (min-width: ${typeof width === 'number' ? width + 'px' : width})`;

export const screenMax = (width: number | string) =>
  `screen and (max-width: ${typeof width === 'number' ? width + 'px' : width})`;

export const vars = themeToVars(mantineTheme);
